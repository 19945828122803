export const FETCH_FARMING_ROUTES_REQUEST = "FETCH_FARMING_ROUTES_REQUEST";
export const FETCH_FARMING_ROUTES_SUCCESS = "FETCH_FARMING_ROUTES_SUCCESS";
export const FETCH_FARMING_ROUTES_FAILURE = "FETCH_FARMING_ROUTES_FAILURE";

export const FETCH_ACTIVE_FARMING_ROUTE_REQUEST =
  "FETCH_ACTIVE_FARMING_ROUTE_REQUEST";
export const FETCH_ACTIVE_FARMING_ROUTE_SUCCESS =
  "FETCH_ACTIVE_FARMING_ROUTE_SUCCESS";
export const FETCH_ACTIVE_FARMING_ROUTE_FAILURE =
  "FETCH_ACTIVE_FARMING_ROUTE_FAILURE";

export const FETCH_IN_GAME_ITEMS_REQUEST = "FETCH_IN_GAME_ITEMS_REQUEST";
export const FETCH_IN_GAME_ITEMS_SUCCESS = "FETCH_IN_GAME_ITEMS_SUCCESS";
export const FETCH_IN_GAME_ITEMS_FAILURE = "FETCH_IN_GAME_ITEMS_FAILURE";

export const SET_ACTIVE_TIMESTAMP = "SET_ACTIVE_TIMESTAMP";
